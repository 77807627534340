import HttpFactory from "~/_api/factory";
import { DiscountCodeDTO } from "@/dto";

class discountCodeModule extends HttpFactory
{
    private RESOURCE = "/discount-code";
    async getByOrganizer(organizerId:number, params?: object): Promise<DiscountCodeDTO> {
        return await this.call<DiscountCodeDTO>(
          "GET",
          `${this.RESOURCE}/organizer/` + organizerId,
            params
        );
       }
}
export default discountCodeModule;
