import HttpFactory from "../../factory";
import type { OrderInvoiceDTO } from "~/dto/order/OrderInvoiceDTO.ts";

class OrderInvoiceModule extends HttpFactory {
  private RESOURCE = "/order-invoice";

  async getByOrgaId( orgaId:number, params:any): Promise<OrderInvoiceDTO> {
    return await this.call<OrderInvoiceDTO>(
      "GET",
      `${this.RESOURCE}/get-by-organizer/` + orgaId,
      params
    );
  }
}
export default OrderInvoiceModule;
