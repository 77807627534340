import HttpFactory from "../../factory";
import type { InvoiceCreditDTO } from "~/dto/InvoiceCreditDTO.ts";

class InvoiceCreditModule extends HttpFactory {
  private RESOURCE = "/invoice-credit";

  async getByOrgaId( orgaId:number, params:any): Promise<InvoiceCreditDTO> {
    return await this.call<InvoiceCreditDTO>(
      "GET",
      `${this.RESOURCE}/organizer/` + orgaId,
      params
    );
  }
}
export default InvoiceCreditModule;


