import HttpFactory from "~/_api/factory";
import { ProductEpassBuyerExtend } from '@/dto/product/ProductEpassBuyerDTO'
import type { ProductExtend } from "~/dto/product/ProductDTO";

class epassModule extends HttpFactory
{
    private RESOURCE = "/epass";
    async getEpassOrganizer(params?: object): Promise<ProductEpassBuyerExtend> {
        return await this.call<ProductEpassBuyerExtend>(
          "GET",
          `${this.RESOURCE}/organizer/` + useOrganizerStore().organizer.orgaId,
            params
        );
       }
}
export default epassModule;
