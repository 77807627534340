class HttpFactory {
  private $fetch: any;

  constructor(fetcher: any) {
    this.$fetch = fetcher;
  }

  /**
   * Make an HTTP call.
   * @param method HTTP method (GET, POST, PUT, DELETE, etc.)
   * @param url Endpoint URL
   * @param data Request body or query parameters
   * @param extras Additional fetch options such as headers, credentials, etc.
   * @returns Promise<T>
   */
  async call<T>(method: string, url: string, data?: object, extras = {}): Promise<T> {
    let fullUrl = url;

    try {
      // Handle query parameters for GET requests
      if (method === 'GET' && data) {
        fullUrl = this.appendQueryParams(url, data);
      }

      // Fetch response
      const response: T = await this.$fetch(fullUrl, {
        method,
        // Only include body for non-GET requests
        ...(method !== 'GET' && { body: data }),
        // ...(responseType && { responseType }),
        ...extras,
      });
      // Handle missing response or unexpected structure
      if (!response) {
        throw new Error('No response from server');
      }

      return response;
    } catch (error: any) {
      this.handleError(error); // Centralized error handler
      throw error; // Re-throw the error to be handled by the caller
    }
  }

  /**
   * Append query parameters to a URL for GET requests.
   * @param url The base URL
   * @param params The query parameters object
   * @returns Full URL with query string
   */
  private appendQueryParams(url: string, params: object): string {
    const query = new URLSearchParams(params as Record<string, string>).toString();
    return `${url}?${query}`;
  }

  /**
   * Centralized error handler for HTTP errors.
   * @param error The error thrown during the fetch call
   */
  private handleError(error: any): void {
    if (error.response) {
      // Handle response errors (e.g., 4xx, 5xx)
      console.error(`HTTP Error: ${error.response.status} ${error.response.statusText}`);
      if (error.response.status >= 500) {
        console.error('Server error occurred.');
      }
    } else if (error.request) {
      // Handle network or request errors (e.g., no internet)
      console.error('Network error: Failed to reach the server.');
    } else {
      // Other unknown errors
      console.error('Unexpected error:', error.message);
    }
  }
}

export default HttpFactory;
