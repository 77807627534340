import HttpFactory from "../../factory";
import type { ICreateAccountInput } from "@/../types/ICreateAccountInput";
import type { ICreateAccountResponse } from "@/../types/ICreateAccountResponse";
import type { ILoginInput } from "@/../types/ILoginInput";
import type { JwtAuthResponse } from "~~/types/JwtAuthResponse";

// Import useRuntimeConfig to access config values
import { useRuntimeConfig } from "#imports";

class AuthModule extends HttpFactory {
  private RESOURCE = "/auth";

  async login(credentials: ILoginInput): Promise<JwtAuthResponse> {
    const oldEndpoint = "event-ticketing-calendar-api/v1/";
    const newEndpoint = "jwt-auth/v1/token";

    // Get the public configuration using useRuntimeConfig()
    const config = useRuntimeConfig();
    const apiLogin = config.public.apiBase.replace(oldEndpoint, newEndpoint);

    return await this.call<JwtAuthResponse>("POST", apiLogin, credentials);
  }

  async validate(): Promise<JwtAuthResponse> {
    const oldEndpoint = "event-ticketing-calendar-api/v1/";
    const newEndpoint = "jwt-auth/v1/token/validate";

    // Get the public configuration using useRuntimeConfig()
    const config = useRuntimeConfig();
    const apiLogin = config.public.apiBase.replace(oldEndpoint, newEndpoint);

    return await this.call<JwtAuthResponse>("POST", apiLogin);
  }

  async refresh(): Promise<JwtAuthResponse> {
    const oldEndpoint = "event-ticketing-calendar-api/v1/";
    const newEndpoint = "jwt-auth/v1/token/refresh";

    // Get the public configuration using useRuntimeConfig()
    const config = useRuntimeConfig();
    const apiLogin = config.public.apiBase.replace(oldEndpoint, newEndpoint);

    return await this.call<JwtAuthResponse>("POST", apiLogin);
  }

  async create(account: ICreateAccountInput): Promise<ICreateAccountResponse> {
    return await this.call<ICreateAccountResponse>(
      "POST",
      `${this.RESOURCE}/register`,
      account
    );
  }
}

export default AuthModule;
