import { defineStore } from 'pinia';
import { OrganizerDTO } from '~/dto/organizer/OrganizerDTO';
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export const useOrganizerStore = defineStore('organizerStore', {
  state: () => ({
    organizer: new OrganizerDTO(), 
  }),
  actions: {
    setOrga(data: any) {
      this.organizer = new OrganizerDTO(data);
    },
  },
  persist: true,
});
