
export class OrganizerDTO {
  public orgaId = 0;
  public title = "";
  public type = "";
  public viviSubscriptionType = "";
  public emailForOrga = "";
  public phoneForOrga = "";
  public address = "";
  public address2 = "";
  public fullAddress = "";
  public image = "";
  public imageLink = "";
  public color = "";
  public excerpt = "";
  public content = "";
  public zip = 0;
  public city = "";
  public userId = 0;
  public transport = "";
  public urlName = "";
  public permalink = "";
  public orgaTypeRNA = "";
  public orgaTypeSIRET = "";
  public orgaType = "";
  public officialName = "";
  public checkinLogoUrl = "";

  // NETWORK
  public site = "";
  public facebook = "";
  public twitter = "";
  public youtube = "";
  public instagram = "";

  // MENTIONS
  public mentionsLegales = "";
  public conditionMain = "";
  public conditionMainPDF = "";
  public conditionRegulationPDF = "";
  public conditionCancel = "";
  public mentionTrial = "";
  public messageCustom = "";
  public TVAMentions = "";
  public TVAPurcentage?: number;

  //TVA
  public TVAAmount?: number;
  public TVANum = "";
  public invoiceBasePublicId?: number;
  public creditBasePublicId?: number;

  // PLANING
  public bookingComissionCbSupported?: boolean;
  public bookingPriceSentence = "";
  public bookingPriceTextarea = "";
  public bookingThankYou = "";
  public bookingNotDisplayEndHour?: boolean;
  public bookingDiscountMessage = "";
  public bookingImportantReminder = "";
  public bookingTrialIsUniquePerWorkshop = false;
  public bookingTrialHowManyPerSeason = 0;

  // COURSES LABEL
  public planningButtonAnnual = "Activités annuelles";
  public planningButtonEpass = "Cartes de cours";
  public planningButtonRegular = "Activités ponctuelles";
  public planningButtonWorkshop = "Stages ou workshops";
  public planningButtonVisio = "Cours en visio";
  public planningButtonTraining = "Formation Professionnelle";

  // COURSES  FILTERS
  public planningIsFilterActivity = true;
  public planningIsFilterPlace = true;
  public planningIsFilterAge = true;
  public planningIsFilterLevel = true;
  public planningIsFilterTeacher = true;

  public emailForUser = "";
  public phoneForUser = "";

  // CONTACT ADMIN
  public orgaFirstName = "";
  public orgaLastName = "";
  public orgaFunction = "";

  // TOOL
  public isToolCheckinActivated = false;
  public isToolPaymentActivated = false;
  public isToolQRCodeActivated = false;

  // DATE
  public searchEngineDateUpdate = "";

  // TaxonomysArt
  public taxonomysArt: number[] = [];

  // FORMER DIGITAL LIST
  public formerIsAccessParticipantPhone = false;
  public formerIsAccessParticipantEmail = false;
  public formerIsAccessParticipantUnpaid = false;
  public formerIsAccessStudentInfo = false;
  public formerIsAccessStudentEmergencyContact = false;
  public formerIsAccessStudentResponsableLegal = false;
  public formerIsAccessStudentOrderInvoice = false;

  // COLOR 
 public  colorPalette:any = [];

  // ADMIN
  public isAdmin = false;

  // QRCode
  public qrCode = "";
  public qrCodePlanning = "";

  //not from backend
  public colorConfig = {
    textColor: "",
    color: "",
    isDark: false,
    shades: {
      // complementary: '',
      light: "",
      standardOrLighterIfDark: "",
      dark: "",
      standardOrDarkerIfLight: "",
    },
  };

  constructor(init?: Partial<OrganizerDTO>) {
    if (init === undefined) return;

    Object.assign(this, init);
  }
}
