import { watchEffect } from 'vue'
import axios from 'axios'

export default defineNuxtPlugin((nuxtApp) => {
  const configNuxt = useRuntimeConfig()
  const { loggedIn, user, session, fetch, clear } = useUserSession()

  axios.defaults.baseURL = configNuxt.public.apiBase
  axios.defaults.headers.common['Content-Type'] = 'application/json'

  watchEffect(() => {
    if (user.value?.token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${user.value.token}`
    } else {
      delete axios.defaults.headers.common['Authorization']
    }
  })
})
