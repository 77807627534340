import type { ArchiveDTO } from "~/dto/ArchiveDTO";
import HttpFactory from "../../factory";

class ArchiveFrontModule extends HttpFactory {
  private RESOURCE = "/archive";
  
  async getFromOrgaId( orgaId: number ): Promise<ArchiveDTO> {
    return await this.call<ArchiveDTO>(
      "GET",
      `${this.RESOURCE}/organizer/` + orgaId
    );
  }
}

export default ArchiveFrontModule;
