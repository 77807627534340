import { defineNuxtPlugin } from "#app";
import AuthModule from "~~/app/_api/modules/authentification/auth";
import UserModule from "~~/app/_api/modules/user/user";
import OrganizerModule from "~~/app/_api/modules/organizer/organizer";
import WorkshopModule from "~/_api/modules/workshop/workshop";
import ArchiveFrontModule from "~/_api/modules/archive/archive";
import PaymentTypeModule from "~/_api/modules/payment/paymentTypes";
import buyerOrganizerModule  from "~/_api/modules/buyer-organizer/buyerOrganizer";
import OrderModule from "~/_api/modules/order/order";
import epassBuyerOrganizerModule from "~/_api/modules/epass-buyer/epassBuyer";
// import OrderItemsModule from "~/_api/modules/order-items/OrderItems";
import orderItemsAll from "~/_api/modules/order-items/orderItemsAll";
import orderInvoices from "~/_api/modules/order-invoice/orderInvoice";
import InvoiceCredit from "~/_api/modules/invoice-credit/InvoiceCredit";
import CalendarReferenceModule from "~/_api/modules/calendar-reference/calendar-reference";
import InvoiceCreditPdf from "~/_api/modules/invoice-credit/gereratePdf";
import participantWithFinance from '~/_api/modules/participant/participantWithFinance';
import restrictionAccess from '~/_api/modules/restriction-access/restrictionAccess';
import epass from "~/_api/modules/epass/epass";
import format from "~/_api/modules/format/format";
import product from "~/_api/modules/product/product";
import discountCode from "~/_api/modules/discount-code/discountCode";
import priceRelations from "~/_api/modules/price-relations/priceRelations";
import restrictioRequest from "~/_api/modules/restriction-request/restrictionRequest"
import email from "~/_api/modules/email/email";
import RegistrationFormDefault from "~/_api/modules/participant/registrationFormDefault";
//import orderPdf from "~/_api/modules/pdf/orderPdf";

interface IApiInstance {
  auth: AuthModule;
  user: UserModule;
  organizer: OrganizerModule;
  buyerOrganizer: buyerOrganizerModule;
  order: OrderModule;
  workshop: WorkshopModule;
  archive: ArchiveFrontModule;
  paymentType: PaymentTypeModule;
  epassBuyerOrganizer: epassBuyerOrganizerModule;
  // orderItem: OrderItemsModule;
  orderItems: orderItemsAll;
  registrationFormDefault: RegistrationFormDefault;  
  orderInvoices: orderInvoices; 
  InvoiceCredit: InvoiceCredit;
  InvoiceCreditPdf: InvoiceCreditPdf;
  calendarReference: CalendarReferenceModule;
  participantWithFinance: participantWithFinance;
  restrictionAccess: restrictionAccess;
  epass: epass;
  format: format;
  product: product;
  discountCode: discountCode;
  priceRelations: priceRelations;
  restrictioRequest : restrictioRequest;
  email: email;
  // orderPdf: orderPdf;
}

/** Helper function to retrieve and set Authorization header */
function setAuthorizationHeader(options: any) {
  const token = localStorage.getItem("token");
  const headers = (options.headers ||= {});
  if (token) {
    if (Array.isArray(headers)) {
      headers.push(["Authorization", `Bearer ${token}`]);
    } else if (headers instanceof Headers) {
      headers.set("Authorization", `Bearer ${token}`);
    } else {
      headers.Authorization = `Bearer ${token}`;
    }
  }

  // Add cookie headers using useRequestHeaders
  const cookieHeaders = useRequestHeaders(["cookie"]);
  Object.assign(headers, cookieHeaders);

  // htaccess - Not working on WP ENgine
  // We don’t do anything custom with CORS for this issue. This would be an unsupported setup that should not be used in production - the password protected sites have caching disabled which will impact performance. So you really should allow public access for live sites and just use normal http requests.
  // const username = "vividev001"; // Your htaccess username
  // const password = "067cba6a"; // Set your htaccess password
  // const basicAuth = btoa(`${username}:${password}`);
  // headers.set("Authorization", `Basic ${basicAuth}`);
}

/** Handle API response errors */
async function handleResponseError({ response }: { response: Response }) {

  switch (response.status) {
    case 401:
      console.warn("Unauthorized, redirecting to login.");
      // Example: Add logic to redirect user or refresh tokens
      await navigateTo('/auth/login');
      break;
    case 404:
      console.error("Resource not found.");
      break;
    case 500:
      console.error("Server error occurred.");
      // Example: Notify user of server error globally
      //notifyError("A server error occurred. Please try again later.");
      break;
    default:
      console.error(`API error: ${response.status} ${response.statusText}`);
      //notifyError("An error occurred while communicating with the server.");
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  // Ensure baseURL is always a string
  const baseURL =
    nuxtApp.$config.public.apiBase ||
    "https://vivilocal.local/wp-json/event-ticketing-calendar-api/v1/";

  const fetchOptions = {
    baseURL, // Now baseURL is guaranteed to be a string
    onRequest({ options }: { options: any }) {
      setAuthorizationHeader(options); // Set the token in the request header if available
      // Add credentials: 'include' to ensure cookies like refresh_token are included
      options.credentials = "include";
    },
    onResponseError({ response }: { response: Response }) {
      handleResponseError({ response }); // Handle 401 and other API errors
    }
  };

  const apiFetcher = $fetch.create(fetchOptions);
  const modules: IApiInstance = {
    auth: new AuthModule(apiFetcher),
    user: new UserModule(apiFetcher),
    organizer: new OrganizerModule(apiFetcher),
    buyerOrganizer: new buyerOrganizerModule(apiFetcher),
    order: new OrderModule(apiFetcher),
    workshop: new WorkshopModule(apiFetcher),
    archive: new ArchiveFrontModule(apiFetcher),
    paymentType: new PaymentTypeModule(apiFetcher),
    epassBuyerOrganizer: new epassBuyerOrganizerModule(apiFetcher),
    // orderItem: new OrderItemsModule(apiFetcher),
    orderItems: new orderItemsAll(apiFetcher),
    registrationFormDefault: new RegistrationFormDefault(apiFetcher),
    orderInvoices: new orderInvoices(apiFetcher),
    InvoiceCredit: new InvoiceCredit(apiFetcher),
    InvoiceCreditPdf: new InvoiceCreditPdf(apiFetcher),
    calendarReference: new CalendarReferenceModule(apiFetcher),
    participantWithFinance: new participantWithFinance(apiFetcher),
    restrictionAccess: new restrictionAccess(apiFetcher),
    epass: new epass(apiFetcher),
    format: new format(apiFetcher),
    product: new product(apiFetcher),
    discountCode: new discountCode(apiFetcher),
    priceRelations: new priceRelations(apiFetcher),
    restrictioRequest: new restrictioRequest(apiFetcher),
    email: new email(apiFetcher),
    // orderPdf: new orderPdf(apiFetcher),
  };

  return {
    provide: {
      api: modules,
    },
  };

});


