import HttpFactory from "../../factory";
import type { InvoiceCreditDTO } from "~/dto/InvoiceCreditDTO.ts";

class InvoiceCreditModule extends HttpFactory {

async get(id?:number): Promise<InvoiceCreditDTO> {
    return await this.call<InvoiceCreditDTO>(
      "GET",
      `/pdf/order-invoice/` + id,
    //     {},
    //     'arraybuffer',
      //  {
      //   "Content-Type": "application/json",
      //   'Accept': "application/pdf",
      //  'Access-Control-Allow-Credentials': true
      //  },
       
    );
  }
}
export default InvoiceCreditModule;
