import HttpFactory from "~/_api/factory";
import { RestrictionRequestExtends } from "@/dto/RestrictionRequestDTO";

class registrationRequestModule extends HttpFactory {
    private RESOURCE = "/restriction-request"
    async getByOrganizer(params:any): Promise<RestrictionRequestExtends[]>{
        return await this.call<RestrictionRequestExtends[]>(
            "GET",
            `${this.RESOURCE}/organizer`,
            params
        )
    }

    async post (id:number, params:any): Promise<RestrictionRequestExtends>{ 
        return await this.call<RestrictionRequestExtends>(
            "POST",
            `${this.RESOURCE}/` + id + '/transform-to-access',
            params
        )
    }
}

export default registrationRequestModule