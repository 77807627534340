import HttpFactory from "../../factory";
import { OrderExtend } from "@/dto";

class OrderModule extends HttpFactory {
  private RESOURCE = "/order";

  async getOrderFromOgaId(orgaId: number, params:any): Promise<OrderExtend> {
    return await this.call<OrderExtend>(
      "GET",
      `${this.RESOURCE}/` + 'get-by-organizer/' + orgaId + '/full-data/',
      params
    );
  }

  async getOrderFromUserId(params:any): Promise<OrderExtend> {
    return await this.call<OrderExtend>(
      "GET",
      `${this.RESOURCE}/get-by-user`,
      params
    );
  }

  async getOrderFromOrderId(orderId: number): Promise<OrderExtend> {
    return await this.call<OrderExtend>(
      "GET",
      `${this.RESOURCE}/` + orderId ,
    );
  }
}
export default OrderModule;



