import HttpFactory from "~/_api/factory";
import { ProductEpassBuyerExtend } from '@/dto/product/ProductEpassBuyerDTO'

class epassBuyerOrganizerModule extends HttpFactory
{
    private RESOURCE = "/epass-buyer";
    async getEpassBuyerOrganizer(orgaId: number, params?: object): Promise<ProductEpassBuyerExtend> {
        return await this.call<ProductEpassBuyerExtend>(
          "GET",
          `${this.RESOURCE}/organizer/` + orgaId,
          params
        );
       }

       async getEpassFromOrderItem(orderItemId: number): Promise<ProductEpassBuyerExtend> {
        return await this.call<ProductEpassBuyerExtend>(
          "GET",
          `${this.RESOURCE}/` + orderItemId + '/order-items',
        );
       }
}
export default epassBuyerOrganizerModule;
