import type { ThemeTypes } from '@/types/themeTypes/ThemeType';

const DARK_BLUE_THEME: ThemeTypes = {
    name: 'DARK_BLUE_THEME',
    dark: true,
    variables: {
        'border-color': '#182D41', 
    },
    colors: {
        primary: '#0085db', 
        secondary: '#7C8FAC',
        info: '#5AC8FA',
        success: '#09C97F', 
        warning: '#F8B15D', 
        error: '#FB977D', 
        indigo:'#8763DA',  
        lightprimary: '#1b394f',
        lightinfo:'#1a3247', 
        lightsecondary: '#2f3f4c',
        lightsuccess: '#102d35', 
        lighterror: '#72483d',
        lightwarning: '#282b32', 
        textPrimary: '#EAEFF4',
        textSecondary: '#fff',
        lightindigo:'#1d233e',  
        borderColor: '#182D41', 
        inputBorder: '#465670',
        containerBg: '#111C2D', 
        background: '#16293e', 
        hoverColor: '#333f55',
        surface: '#111C2D', 
        grey100: '#7C8FAC', 
        grey200: '#EAEFF4', 
        darkbg:'#111936'
    }
};

const DARK_AQUA_THEME: ThemeTypes = {
    name: 'DARK_AQUA_THEME',
    dark: true,
    variables: {
        'border-color': '#333F55',
    },
    colors: {
        primary: '#0074BA',
        secondary: '#47D7BC',
        info: '#5AC8FA',
        success: '#09C97F', 
        warning: '#F8B15D', 
        error: '#FB977D', 
        indigo:'#8763DA',  
        lightprimary: '#1b394f',
        lightinfo:'#1a3247', 
        lightsecondary: '#2f3f4c',
        lightsuccess: '#102d35', 
        lighterror: '#72483d',
        lightwarning: '#282b32', 
        textPrimary: '#EAEFF4',
        textSecondary: '#fff',
        lightindigo:'#1d233e',  
        borderColor: '#182D41', 
        inputBorder: '#465670',
        containerBg: '#111C2D', 
        background: '#16293e', 
        hoverColor: '#333f55',
        surface: '#111C2D', 
        grey100: '#7C8FAC', 
        grey200: '#EAEFF4', 
        darkbg:'#111936'
    }
};

const DARK_PURPLE_THEME: ThemeTypes = {
    name: 'DARK_PURPLE_THEME',
    dark: true,
    variables: {
        'border-color': '#333F55',
    },
    colors: {
        primary: '#763EBD',
        secondary: '#95CFD5',
        info: '#5AC8FA',
        success: '#09C97F', 
        warning: '#F8B15D', 
        error: '#FB977D', 
        indigo:'#8763DA',  
        lightprimary: '#1b394f',
        lightinfo:'#1a3247', 
        lightsecondary: '#2f3f4c',
        lightsuccess: '#102d35', 
        lighterror: '#72483d',
        lightwarning: '#282b32', 
        textPrimary: '#EAEFF4',
        textSecondary: '#fff',
        lightindigo:'#1d233e',  
        borderColor: '#182D41', 
        inputBorder: '#465670',
        containerBg: '#111C2D', 
        background: '#16293e', 
        hoverColor: '#333f55',
        surface: '#111C2D', 
        grey100: '#7C8FAC', 
        grey200: '#EAEFF4', 
        darkbg:'#111936'
    }
};

const DARK_GREEN_THEME: ThemeTypes = {
    name: 'DARK_GREEN_THEME',
    dark: true,
    variables: {
        'border-color': '#333F55',
    },
    colors: {
        primary: '#0A7EA4',
        secondary: '#CCDA4E',
        info: '#5AC8FA',
        success: '#09C97F', 
        warning: '#F8B15D', 
        error: '#FB977D', 
        indigo:'#8763DA',  
        lightprimary: '#1b394f',
        lightinfo:'#1a3247', 
        lightsecondary: '#2f3f4c',
        lightsuccess: '#102d35', 
        lighterror: '#72483d',
        lightwarning: '#282b32', 
        textPrimary: '#EAEFF4',
        textSecondary: '#fff',
        lightindigo:'#1d233e',  
        borderColor: '#182D41', 
        inputBorder: '#465670',
        containerBg: '#111C2D', 
        background: '#16293e', 
        hoverColor: '#333f55',
        surface: '#111C2D', 
        grey100: '#7C8FAC', 
        grey200: '#EAEFF4', 
        darkbg:'#111936'
    }
};

const DARK_CYAN_THEME: ThemeTypes = {
    name: 'DARK_CYAN_THEME',
    dark: true,
    variables: {
        'border-color': '#333F55',
    },
    colors: {
        primary: '#01C0C8',
        secondary: '#FB9678',
        info: '#5AC8FA',
        success: '#09C97F', 
        warning: '#F8B15D', 
        error: '#FB977D', 
        indigo:'#8763DA',  
        lightprimary: '#1b394f',
        lightinfo:'#1a3247', 
        lightsecondary: '#2f3f4c',
        lightsuccess: '#102d35', 
        lighterror: '#72483d',
        lightwarning: '#282b32', 
        textPrimary: '#EAEFF4',
        textSecondary: '#fff',
        lightindigo:'#1d233e',  
        borderColor: '#182D41', 
        inputBorder: '#465670',
        containerBg: '#111C2D', 
        background: '#16293e', 
        hoverColor: '#333f55',
        surface: '#111C2D', 
        grey100: '#7C8FAC', 
        grey200: '#EAEFF4', 
        darkbg:'#111936'
    }
};

const DARK_ORANGE_THEME: ThemeTypes = {
    name: 'DARK_ORANGE_THEME',
    dark: true,
    variables: {
        'border-color': '#333F55',
    },
    colors: {
        primary: '#FA896B',
        secondary: '#0074BA',
        info: '#5AC8FA',
        success: '#09C97F', 
        warning: '#F8B15D', 
        error: '#FB977D', 
        indigo:'#8763DA',  
        lightprimary: '#1b394f',
        lightinfo:'#1a3247', 
        lightsecondary: '#2f3f4c',
        lightsuccess: '#102d35', 
        lighterror: '#72483d',
        lightwarning: '#282b32', 
        textPrimary: '#EAEFF4',
        textSecondary: '#fff',
        lightindigo:'#1d233e',  
        borderColor: '#182D41', 
        inputBorder: '#465670',
        containerBg: '#111C2D', 
        background: '#16293e', 
        hoverColor: '#333f55',
        surface: '#111C2D', 
        grey100: '#7C8FAC', 
        grey200: '#EAEFF4', 
        darkbg:'#111936'
    }
};

export { DARK_BLUE_THEME, DARK_AQUA_THEME, DARK_ORANGE_THEME, DARK_PURPLE_THEME, DARK_GREEN_THEME, DARK_CYAN_THEME };
