import HttpFactory from "~/_api/factory";
import { BuyerOrganizerDTO } from "@/dto";

class buyerOrganizerModule extends HttpFactory
{
    private RESOURCE = "/buyer-organizer";
    async getBuyerOrganizerFromText(params?: object): Promise<BuyerOrganizerDTO> {
        return await this.call<BuyerOrganizerDTO>(
          "GET",
          `${this.RESOURCE}/search-by-text`, // No body for GET requests
          params
        );
      }

      async postBuyerOrganizer(params?: object): Promise<BuyerOrganizerDTO> {
        return await this.call<BuyerOrganizerDTO>(
          "POST",
          this.RESOURCE,
         params
        );
        }
    }
export default buyerOrganizerModule ;
