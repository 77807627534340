import HttpFactory from "~/_api/factory";
import { ProductFormatExtend } from "@/dto";

class formatModule extends HttpFactory
{
    private RESOURCE = "/format";
    async getByWorkshop(workshopId : number, params?:any): Promise<ProductFormatExtend> {
        return await this.call<ProductFormatExtend>(
          "GET",
          `v2${this.RESOURCE}/workshop/` + workshopId,
            params
        );
       }
}
export default formatModule;