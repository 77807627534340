import HttpFactory from "../../factory";
import { CalendarReferenceDTO } from "~/dto/CalendarReferenceDTO";

class CalendarReferenceModule extends HttpFactory {
  private RESOURCE = "/calendar-reference";

  async get(): Promise<CalendarReferenceDTO> {
    return await this.call<CalendarReferenceDTO>(
      "GET",
      `${this.RESOURCE}`
    )
  }

  async post(calendarReference: CalendarReferenceDTO): Promise<CalendarReferenceDTO> {
    return await this.call<CalendarReferenceDTO>(
      "POST",
      `${this.RESOURCE}`,
      calendarReference
    )
  }

  async delete(calendarReferenceId: number)  {
    const data = {
      id: calendarReferenceId,
    };
    
    await this.call("DELETE",`${this.RESOURCE}`,data);
  }
}

export default CalendarReferenceModule;