import HttpFactory from "~/_api/factory";
import { ProductEpassBuyerExtend } from '@/dto/product/ProductEpassBuyerDTO'

class emailModule extends HttpFactory
{
    private RESOURCE = "/email-sender";
    async put(params?: object): Promise<any> {
        return await this.call<any>(
          "GET",
          `${this.RESOURCE}`,
            params
        );
       }
}
export default emailModule;
