import HttpFactory from "~/_api/factory";
import { PaymentTypeDTO } from "~/dto/PaymentTypeDTO";

class PaymentTypeModule extends HttpFactory {
    private RESOURCE = "/payment-type"

    // GET ALL TYPES OF PAYEMENT - ONE ORGANIZER
    async get( organizer : { id: number } ): Promise<PaymentTypeDTO[]>{
        return await this.call<PaymentTypeDTO[]>(
            "GET",
            `${this.RESOURCE}/organizer/` + organizer.id
        )
    }
    
    // UPDATE ONE TYPE OF PAYMENT
    async post(paymentType : PaymentTypeDTO) :  Promise<PaymentTypeDTO> {
        return await this.call<PaymentTypeDTO>(
            "POST",
            `${this.RESOURCE}`,
            paymentType
        )
    }
}

export default PaymentTypeModule