import HttpFactory from "~/_api/factory";
import { PriceRelationExtend } from "~/dto/PriceRelationDTO";

class priceRelationpeModule extends HttpFactory {
    private RESOURCE = "/price-relation"

    async getFromProduct(params?:any): Promise<PriceRelationExtend[]>{
        return await this.call<PriceRelationExtend[]>(
            "GET",
            `${this.RESOURCE}/product/`,
            params
        )
    }
}
export default priceRelationpeModule
