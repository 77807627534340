import HttpFactory from "../../factory";
import type { OrganizerDTO } from "@/dto/organizer/OrganizerDTO";

interface ExtendMetaData {
  extendMeta: boolean;
}

class UserModule extends HttpFactory {
  private RESOURCE = "/user";

  async get(
    data: ExtendMetaData
  ): Promise<OrganizerDTO> {
    return await this.call<OrganizerDTO>(
      "GET",
      `${this.RESOURCE}`,
      data
    );
  }
}

export default UserModule;
