import HttpFactory from "../../factory";
import type { OrganizerDTO } from "@/dto/organizer/OrganizerDTO";

class OrganizerModule extends HttpFactory {
  private RESOURCE = "/organizer";

  async get( organizer: { id: number } ): Promise<OrganizerDTO> {
    return await this.call<OrganizerDTO>(
      "GET",
      `${this.RESOURCE}/` + organizer.id
    );
  }
  
  async getQRCode(organizer: { id: number } ): Promise<OrganizerDTO> {
    return await this.call<OrganizerDTO>(
      "GET",
      `${this.RESOURCE}/` + organizer.id + `/qr-code`
    );
  }
}

export default OrganizerModule;
