import HttpFactory from "../../factory";
import type { OrganizerDTO } from "@/dto/organizer/OrganizerDTO";

class WorkshopModule extends HttpFactory {
  private RESOURCE = "/workshop";

  async getFromOrganizerId(
    organizerId: number,
    params?: object
  ): Promise<OrganizerDTO> {
    return await this.call<OrganizerDTO>(
      "GET",
      `${this.RESOURCE}/organizer/` + organizerId + "/status",
      undefined, // No body for GET requests
      params
    );
  }

  async getQRCode(organizer: { id: number }): Promise<OrganizerDTO> {
    return await this.call<OrganizerDTO>(
      "GET",
      `${this.RESOURCE}/` + organizer.id + `/qr-code`
    );
  }
  async getFromArchiveId(params?:any):Promise<OrganizerDTO> {
    return await this.call<OrganizerDTO>(
      "GET",
      `${this.RESOURCE}`,
      params
    );
  }
}

export default WorkshopModule;