import HttpFactory from "../../factory";
import { ParticipantExtend } from "@/dto/ParticipantDTO";

class participantWithFinanceModule extends HttpFactory {
private RESOURCE = "/participants";

async getFull(params:any): Promise<ParticipantExtend> {
    return await this.call<ParticipantExtend>(
      "GET",
      `${this.RESOURCE}/full-data/organizer`,
      params
    );
  }
}
export default participantWithFinanceModule;


