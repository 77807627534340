import HttpFactory from "~/_api/factory";
import { ProductExtend } from "@/dto/product/ProductDTO";

class productModule extends HttpFactory {
    private RESOURCE = "/product"

    // GET ALL TYPES OF PAYEMENT - ONE ORGANIZER
    async getByOrganizer( organizerId:number , params?:any): Promise<ProductExtend[]>{
        return await this.call<ProductExtend[]>(
            "GET",
            `${this.RESOURCE}/organizer/` + organizerId,
            params
        )
    }
    

}
export default productModule