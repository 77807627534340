import HttpFactory from "~/_api/factory";
import { RestrictionAccessDTO } from "~/dto/RestrictionAccessDTO";

class restrictiontAccessModule extends HttpFactory {
    private RESOURCE = "/restriction-access"
    async get(params:any): Promise<RestrictionAccessDTO[]>{
        return await this.call<RestrictionAccessDTO[]>(
            "GET",
            `${this.RESOURCE}/organizer`,
            params
        )
    }
    async delete(params:any): Promise<RestrictionAccessDTO[]>{
        return await this.call<RestrictionAccessDTO[]>(
            "DELETE",
            `${this.RESOURCE}`,
            params
        )
    }

    async put(params:any): Promise<RestrictionAccessDTO[]>{
        return await this.call<RestrictionAccessDTO[]>(
            "PUT",
            `${this.RESOURCE}`,
            params
        )
    }
}

export default restrictiontAccessModule
