import HttpFactory from "../../factory";
import { OrderItemDTO } from "@/dto/order/OrderItemDTO";

class OrderItemsAll extends HttpFactory {
  private RESOURCE = "/order-items";

  async getOrderItems(orgaId:number, params:any): Promise<OrderItemDTO[]> {
    return await this.call<OrderItemDTO[]>(
      "GET",
      `${this.RESOURCE}/organizer/all/` + orgaId,
      params
    );
  }
}

export default OrderItemsAll;
