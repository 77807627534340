import type { FieldCategoryDTO } from "~/dto/FieldCategoryDTO";
import HttpFactory from "../../factory";

class RegistrationFormDefault extends HttpFactory {
private RESOURCE = "/registration-form-default";

async get(): Promise<FieldCategoryDTO> {
    return await this.call<FieldCategoryDTO>(
      "GET",
      `${this.RESOURCE}`,
    );
  }
}
export default RegistrationFormDefault;