const authRoutes = ["/auth/login"];
const publicRoutes = ["/code/url"];

let isFirst = true;

export default defineNuxtRouteMiddleware(async (to, from) => {
  
  if (publicRoutes.includes(to.path)) {
    return;
  }
  
  // ONLY IN BROWSER
  if (!window && isFirst) {
    isFirst = false;
    return;
  }

  const { loggedIn, fetch } = useUserSession();
  await fetch();

  // LOGIN
  if (loggedIn.value === false) {
    const targetLogin = "/auth/login";
    const targetRegister = "/auth/register";

    if (to.fullPath !== targetLogin && to.fullPath !== targetRegister) {
      window.location.href = "/auth/login";
      return abortNavigation();
    }
    return;
  }

  if (!authRoutes.includes(to.path)) {
    return;
  }

  // OUTIL ORGANISATEUR
  const targetRoute = "/dashboard/daily/planning";
  if (to.fullPath !== targetRoute) {
    window.location.href = targetRoute;
    return abortNavigation();
  }
});
